import { render, staticRenderFns } from "./DeleteIcon.vue?vue&type=template&id=372f5e89&"
import script from "./DeleteIcon.vue?vue&type=script&lang=js&"
export * from "./DeleteIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/alexbilyk/Projects-Apps/pa/pathadvice-connect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('372f5e89')) {
      api.createRecord('372f5e89', component.options)
    } else {
      api.reload('372f5e89', component.options)
    }
    module.hot.accept("./DeleteIcon.vue?vue&type=template&id=372f5e89&", function () {
      api.rerender('372f5e89', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/icons/DeleteIcon.vue"
export default component.exports