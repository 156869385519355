<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="23.5"
      height="23.5"
      rx="5.25"
      stroke-width="1.5"
      :stroke="color"
    />

    <path
      d="M7.85698 9.85547H8.85698H16.857"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      :stroke="color"
    />

    <path
      d="M10.3569 9.85547V8.85547C10.3569 8.59025 10.4623 8.3359 10.6498 8.14836C10.8374 7.96083 11.0917 7.85547 11.3569 7.85547H13.3569C13.6221 7.85547 13.8765 7.96083 14.064 8.14836C14.2516 8.3359 14.3569 8.59025 14.3569 8.85547V9.85547M15.8569 9.85547V16.8555C15.8569 17.1207 15.7516 17.375 15.564 17.5626C15.3765 17.7501 15.1221 17.8555 14.8569 17.8555H9.85693C9.59171 17.8555 9.33736 17.7501 9.14982 17.5626C8.96229 17.375 8.85693 17.1207 8.85693 16.8555V9.85547H15.8569Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      :stroke="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#F05541',
      required: false,
    },
  },
}
</script>
